//
// Google font - Poppins
//

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

@font-face {
    font-family: "Inter";
    src: local("InterRegular"), url("../../../fonts/Inter-Regular.ttf") format("truetype")
}

@font-face {
    font-family: "InterMedium";
    src: local("InterMedium"), url("../../../fonts/Inter-Medium.ttf") format("truetype")
}