.popup-content {
    position: relative;
    background: white;
    padding: 10px;
    width: 400px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }
  
  .popup-content.right {
    animation: slideInRight 0.5s forwards;
  }
  
  .popup-content.left {
    animation: slideInLeft 0.5s forwards;
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }