@import "../../assets/scss/theme.scss";

.table-white {
  background-color: #fff !important;

  tr,
  td,
  th {
    background-color: #fff !important;
  }

  td.tr-active {
    background-color: $light !important;
    font-weight: bold!important;
  }
}
