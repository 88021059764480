
@import "../../assets/scss/theme.scss";

.closable-tag {
    display: inline-flex;
    align-items: center;
    background-color: #b3b3b3;
    color: white;
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 5px;
  }
  
  .close-button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 5px;
  }