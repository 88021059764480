@import '../../assets/scss/theme.scss';

.timeline {
    margin: 50px 60px;
    height: 4px;
    background-color: #ccc;
  }
  
  .timeline-progress {
      width: 50%;
      height: 100%;
      background-color: $green;
  }
  
  .timeline-items {
      margin-left: -10px;
      margin-right: -10px;
      margin-top: -12px;
      display: flex;
      justify-content: space-between;
  }
  
  .timeline-item {
      position: relative;
  }
  
  .timeline-item::before {
      content: '';
      width: 20px;
      height: 20px;
      background-color: #ccc;
      display: block;
      border-radius: 100%;
  }
  
  .timeline-content {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ddd;
      width: 100px;
      padding: 5px 10px;
      border-radius: 5px;
      text-align: center;
  }
  
  .timeline-content::before {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #ddd;
      display: block;
      position: absolute;
      top: -8px;
      margin-left: -2px;
      left: 50%;
      transform: rotate(-45deg) translateX(-50%);
  }
  
  .timeline-item.active::before {
      background-color: $green;
      cursor: not-allowed;
  }
  
  .timeline-item.active .timeline-content {
      color: #fff;
      background-color: orange;
  }
  
  .timeline-item.active .timeline-content::before {
      background-color: orange;
  }
  
  .not-allowed {
    cursor: not-allowed;
  }


.pl-20 {
    padding-left: 20px!important;
}

.text-red {
    color:red
}