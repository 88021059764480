@import '../../assets/scss/theme.scss';

:root {
    --card-line-height: 1.2em;
    --card-padding: 1em;
    --card-radius: 0.5em;
    --color-green: #558309;
    --color-gray: #e2ebf6;
    --color-dark-gray: #c4d1e1;
    --radio-border-width: 2px;
    --radio-size: 1.5em;
  }

.bg-primary {
    background-color: $primary;
}

.bg-secondary-orange {
    background-color: $secondary-orange!important;
}

.text-white {
    color: white;
}

.text-bg-secondary-orange {
    color: $secondary-orange!important;
}

.bg-danger {
    background-color: rgba(244, 106, 106, 0.18) !important
}

.font-size-28 {
    font-size: 28px !important;
}



.bg-yello {
  background-color:#EF7B2D!important;
}

.radio {
    font-size: inherit;
    margin: 0;
    position: absolute;
    right: calc(var(--card-padding) + var(--radio-border-width));
    top: calc(var(--card-padding) + var(--radio-border-width));
  }

  .activeConfirm {
    border-bottom: 3px solid $success !important;
  }

  .active-exception {
    border-bottom: 3px solid $danger !important;
  }

  .active-submitted {
    border-bottom: 3px solid $secondary-orange !important;
  }

  .active-pending-approvals {
    border-bottom: 3px solid $warning !important;
  }

  .active-new-request {
    border-bottom: 3px solid #EF7B2D !important;
  }

  .active-all {
    border-bottom: 3px solid $primary !important;
  }

  .text-secondary-orange {
    color: $secondary-orange!important;
  }



  .text-yellow {
    color:#EF7B2D!important;
  }

  @supports(-webkit-appearance: none) or (-moz-appearance: none) { 
    .radio {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: #fff;
      border: var(--radio-border-width) solid var(--color-gray);
      border-radius: 50%;
      cursor: pointer;
      height: var(--radio-size);
      outline: none;
      transition: 
        background 0.2s ease-out,
        border-color 0.2s ease-out;
      width: var(--radio-size); 
  
      &::after {
        border: var(--radio-border-width) solid #fff;
        border-top: 0;
        border-left: 0;
        content: '';
        display: block;
        height: 0.75rem;
        left: 25%;
        position: absolute;
        top: 50%;
        transform: 
          rotate(45deg)
          translate(-50%, -50%);
        width: 0.375rem;
      }
  
      &:checked {
        background: var(--color-green);
        border-color: var(--color-green);
      }
    }
    
    .card:hover .radio {
      border-color: var(--color-dark-gray);
      
      &:checked {
        border-color: var(--color-green);
      }
    }
  }

